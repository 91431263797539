/* eslint-disable @typescript-eslint/no-misused-promises */
import { Injectable } from '@angular/core';
import Swal, { SweetAlertCustomClass, SweetAlertIcon, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { TranslateService } from '../translate';
import { AlertInterface } from './alert.interface';

interface ConfirmOptions {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

@Injectable({ providedIn: 'root' })
export class AlertService implements AlertInterface {
  private readonly defaultOptions: SweetAlertOptions = {
    confirmButtonColor: '#1B8EB7',
    cancelButtonColor: '#F64846',
  };

  private readonly customClass: SweetAlertCustomClass = {
    confirmButton: 'btn btn-lg btn-primary',
    cancelButton: 'btn btn-lg btn-danger',
  };

  constructor(private readonly translateService: TranslateService) {}

  public async message(html: string): Promise<void> {
    const options: SweetAlertOptions = {
      html,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) }).then(() => {});
  }

  public async success(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'success';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_SUCESSO'),
      html: text,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) }).then(() => {});
  }

  public async error(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'error';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_ERRO'),
      html: text,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) }).then(() => {});
  }

  public async warning(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'warning';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_ATENCAO'),
      html: text,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) }).then(() => {});
  }

  public async info(text: string, title?: string): Promise<void> {
    const icon: SweetAlertIcon = 'info';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_INFORMACAO'),
      html: text,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) }).then(() => {});
  }

  public async question(text: string, title?: string): Promise<SweetAlertResult<any>> {
    const icon: SweetAlertIcon = 'question';
    const options: SweetAlertOptions = {
      icon,
      title: title ? title : this.translateService.translate('ALERTA_QUESTAO'),
      html: text,
      showConfirmButton: true,
      confirmButtonText: this.translateService.translate('BOTAO_SIM'),
      showCancelButton: true,
      cancelButtonText: this.translateService.translate('BOTAO_NAO'),
      focusConfirm: false,
      focusCancel: true,
      showCloseButton: true,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(options as any) });
  }

  public async confirm(options: ConfirmOptions): Promise<SweetAlertResult<any>> {
    const icon: SweetAlertIcon = 'question';
    const swalOptions: SweetAlertOptions = {
      icon,
      title: options.title,
      html: options.message,
      showConfirmButton: true,
      confirmButtonText: options.confirmButtonText || this.translateService.translate('BOTAO_SIM'),
      showCancelButton: true,
      cancelButtonText: options.cancelButtonText || this.translateService.translate('BOTAO_NAO'),
      focusConfirm: false,
      focusCancel: true,
      showCloseButton: true,
    };
    return Swal.fire({ customClass: this.customClass, ...this.defaultOptions, ...(swalOptions as any) });
  }
}
/* eslint-enable @typescript-eslint/no-misused-promises */
